<template>
  <div class="changeserve">
    <title-top>退换服务</title-top>

    <div class="row align-center bg-white mb20" v-for="(item,index) in res.items" :key="index">
      <img class="imgsize176 br4 ml25 mr16 mt25 mb25" :src="item.image_url" alt />
      <div class="flex1 font24 column mt25 mb25">
        <p class="f-1A1A1A mr25 ellipsis">{{item.product_name}}</p>
        <div class="row between f-999999 mt16 align-center">
          <span>{{item.specification_values | spec}}</span>
          <span class="ml25 mr25">×{{item.quantity}}</span>
        </div>
      </div>
    </div>
    <div class="row between align-center bg-white h118" @click="onApply(true)" v-if="isbtn1">
      <div class="row align-center ml25">
        <img class="imgsize44" src="~images/zyimg/tui.png" alt />
        <div class="column ml16">
          <span class="font24 f-1A1A1A">退货退款</span>
          <span class="font22 f-999999 mt8">因质量、错发等问题需要退货退款，或因漏发需要退款</span>
        </div>
      </div>
      <img class="imgsize24 mr25" src="~images/zyimg/nextbtn.png" alt />
    </div>
    <div class="line"></div>
    <div class="row between align-center bg-white h118" @click="onApply(false)" v-if="isbtn2">
      <div class="row align-center ml25">
        <img class="imgsize44" src="~images/zyimg/rebuy.png" alt />
        <div class="column ml16">
          <span class="font24 f-1A1A1A">仅退款</span>
          <span class="font22 f-999999 mt8">拒收包裹或者未收到包裹</span>
        </div>
      </div>
      <img class="imgsize24 mr25" src="~images/zyimg/nextbtn.png" alt />
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import TitleTop from "@/components/common/title/TitleTop";
import {refunds_new} from "network/igou2";

export default {
  name: "ChangeServe",
  data() {
    return {
      order_id:this.$route.query.order_id,
      res:{},
      isbtn1:false,
      isbtn2:false,
      state:this.$route.query.state
    };
  },
  mounted() {
    this.showBtn(this.state)
    this._refunds_new();
  },
  methods: {
    showBtn(state){
      if(state !=null) {
        if(state == 'paid'){
          this.isbtn1 = false;
          this.isbtn2 = true;
        }else if(state == 'shipped'){
          this.isbtn1 = true;
          this.isbtn2 = true;
        }else if(state == 'received'){
          this.isbtn1 = true;
          this.isbtn2 = false;
        }
      }
    },
    onApply(need_return){
      this.$router.push({
        path:'/zyapplyrefund',
        query:{order_id:this.order_id,need_return:need_return}
      });
    },
    _refunds_new(){
      refunds_new(this.order_id).then(res=>{
        console.log(res)
        this.res = res.data.order;
      })
    }
  },
  filters: {
    spec(specification_values) {
      let str = '';
      specification_values.forEach(item => {
        str += item.content+","
      });
      if(str){
        str.substring(0,str.length-1);
      }
      return str;
    },
  },
  components: {
    TitleTop
  }
};
</script>
<style  scoped>
</style>
